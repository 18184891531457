export class PageNotFoundError extends Error {
    constructor(msg?: string, public statusCode = 404) {
        super(msg || 'Page not found');
    }
}

export class ServerError extends Error {
    constructor(msg?: string, public statusCode = 500) {
        super(msg || 'Server error');
    }
}