
import Vue from 'vue';
import * as ContentStackCommon from '@mca-components-ui/contentstack-common';
import * as ContentStackProducts from '@mca-components-ui/contentstack-products';
import * as ContentStackSolutions from '@mca-components-ui/contentstack-solutions';
import { Contentstack } from '@mca-components-ui/models';

import MdsModal from '@mds/modal';

import MdsSection from '@mds/section';
import { MdsButton, MdsButtonContainer } from '@mds/button';
import { COMPONENTS } from '@/configs/contentstack';
import { Types as SchemaTypes } from '@mca-dfd-api/graphql-schema';
import { Gtm } from '~/configs/common';

type IContentStackBlock = {
    uid: string;
    data: Record<string, string>;
};

type IContentStackData = {
    content_sections: any[]
};

export default Vue.extend({
    props: {
        data: {
            type: Object as () => IContentStackData
        }
    },
    data() {
        return {
            COMPONENTS,
            form: {
                clickedButtonId: "",
                visible: false,
                data: undefined as Contentstack.Components.Common.Form | undefined
            }
        };
    },
    components: {
        MdsModal,
        MdsSection,
        MdsButton,
        MdsButtonContainer
    },
    mounted() {
        this.$store.dispatch('eloqua/init');
    },
    computed: {
        blocks(): IContentStackBlock[] {
            return this.data.content_sections.map((item) => {
                const uid = Object.keys(item)[0] as ContentStackCommon.Types.BlockId | ContentStackProducts.Types.BlockId | ContentStackSolutions.Types.BlockId;
                return {
                    uid,
                    data: this.getDataModel(uid, item[uid].section[0])
                };
            });
        }
    },
    methods: {
        getDataModel(uid: ContentStackCommon.Types.BlockId | ContentStackProducts.Types.BlockId | ContentStackSolutions.Types.BlockId, data: unknown): any {
            let model;
            if (ContentStackCommon.Models.TypeMap.has(uid as ContentStackCommon.Types.BlockId)) {
                model = ContentStackCommon.Models.TypeMap.get(uid as ContentStackCommon.Types.BlockId) as ObjectConstructor;
            }
            else if (ContentStackProducts.Models.TypeMap.has(uid as ContentStackProducts.Types.BlockId)) {
                model = ContentStackProducts.Models.TypeMap.get(uid as ContentStackProducts.Types.BlockId) as unknown as ObjectConstructor;
            }
            else if (ContentStackSolutions.Models.TypeMap.has(uid as ContentStackSolutions.Types.BlockId)) {
                model = ContentStackSolutions.Models.TypeMap.get(uid as ContentStackSolutions.Types.BlockId) as unknown as ObjectConstructor;
            }
            else {
                throw Error(`Block does not exist: ${uid}`);
            }
            return new model(data);
        },
        openEloquaForm(buttonData: ContentStackCommon.Models.CtaButton) {
            const form = buttonData?.meta?.form;
            if(form) {
                this.form.visible = true;
                this.form.clickedButtonId = buttonData.button_id;
                this.form.data = new Contentstack.Components.Common.Form(form[0] || form);
            }
        },
        onFormSubmit({ resp }: { resp: SchemaTypes.EloquaFormSubmitResponse}) {

            if(resp.id) {
                this.$gtm.push({
                    event: Gtm.eventName,
                    category: 'lead',
                    label: location.href,
                    action: this.form.clickedButtonId
                });
                this.goToThankYouPage(this.form.data?.thankYouUrl);
            }
            this.form.visible = false;
        },
        goToThankYouPage(url?:string) {
            if(url) {
                if(url.indexOf("http") == 0) {
                    window.location.href = url;
                }
                else{
                    this.$router.push({ path: url });
                }
            }
        }
    }
});
