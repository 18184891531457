
import Vue from 'vue';
import MdsLoader from '@mds/loader';
import MdsAlert from '@mds/alert';
import McaEloquaForm from '@mca-components-ui/eloqua-form';
import { Forms, Contentstack } from '@mca-components-ui/models';
import type { MCA } from '@mca-components-ui/types';

export default Vue.extend({
    props: {
        data: {
            type: Object as ()=> Contentstack.Components.Common.Form,
            required: true
        },
        isModal: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            isSubmitting: false,
            errorOnSubmit: false,
        };
    },
    components: {
        MdsLoader,
        McaEloquaForm,
        MdsAlert
    },
    async fetch() {
        await this.$store.dispatch('eloqua/form/load', this.data.id);
    },
    fetchOnServer: false,
    computed: {
        formParams(): Record<string, string | undefined> {
            const contentUtm = this.data.utm || {};
            const params = {
                ...this.$store.state.eloqua.additionalParams,
                ...{
                    fullurl: location?.href,
                }
            };
            if (this.$nuxt.context.from) {
                Object.assign(params, { hiddenReferrerUrl: this.$nuxt.context.from.fullPath });
            }
            const campaignParams = this.$nuxt.context.$getCampaign();

            if(campaignParams) {
                if(!campaignParams.hasUtmParams(campaignParams)) {
                    campaignParams.updateUtm(contentUtm);
                }
                return {...params, ...campaignParams };
            }
            return {...params, ...contentUtm};
        },
        formData(): Forms.Eloqua.EloquaForm | undefined {
            const formConfig = this.$store.state.eloqua.form.configs.find((f: Forms.Eloqua.EloquaForm)=> f.id == this.data.id);
            if(formConfig) {

                let eloquaForm = new Forms.Eloqua.EloquaForm(formConfig);
                eloquaForm.setElementValues(this.formParams);

                eloquaForm = this.hideFormElement(["FirmType", "RoleType"], eloquaForm);

                return eloquaForm;
            }
            return undefined;
        },
        errorAlertElement(): HTMLElement | undefined {
            const formErrorRef = this.$refs?.formErrorAlert as any;
            return formErrorRef.$el;
        }
    },
    methods: {
        inputChange(element: Forms.Eloqua.FormElement) {
            if (element.htmlName === "FPorll") {
                if (element.value?.toLowerCase() === "yes") {
                    this.showFormElement(["FirmType", "RoleType"]);
                }
                else {
                    this.hideFormElement(["FirmType", "RoleType"], this.formData as Forms.Eloqua.EloquaForm);
                }
            }
        },
        hideFormElement(elems: string[], form: Forms.Eloqua.EloquaForm) {
            form?.elements.map(x => {
                if(elems.includes(x.htmlName)) x.isVisible = false;
            });
            return form;
        },
        showFormElement(elems: string[]) {
            this.formData?.elements.map(x => {
                if(elems.includes(x.htmlName)) x.isVisible = true;
            });
        },
        async onFormSubmit(form: MCA.Forms.IEloquaFormData) {

            this.isSubmitting = true;
            this.errorOnSubmit = false;
            try {
                const formObject: Forms.Eloqua.EloquaForm = new Forms.Eloqua.EloquaForm(form);
                const formDataValues = formObject.getElementValues();

                const resp = await this.$store.dispatch('eloqua/form/submit', { id: formObject.id, data: formDataValues});
                this.$emit("on-form-submit", { resp, form: formObject});
            }
            catch(e) {
                this.errorOnSubmit = true;
                this.$nextTick(() => {
                    if(this.errorAlertElement) {
                        this.errorAlertElement.focus();
                    }
                });
                this.$emit("on-form-submit-error", e);
            }

            this.isSubmitting = false;
        },
    }
});
