import { render, staticRenderFns } from "./_product.vue?vue&type=template&id=196c065a"
import script from "./_product.vue?vue&type=script&lang=ts"
export * from "./_product.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ErrorsPageError: require('/user/src/app/app/components/errors/page-error.vue').default,MarketingContentstackBlocks: require('/user/src/app/app/components/marketing/contentstack/blocks.vue').default})
