
import Vue from "vue";

export default Vue.extend({
    props: {
        message: {
            type: String,
            default:
                "We’re currently having some problems with our data feed. We apologise for any inconvenience.",
        },
    },
});
