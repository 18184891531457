import { getFirstEntry } from '~/services/content-stack/common';
import { getStack } from '~/services/content-stack/stack';

export async function getProductFromContentStack(productSlugID: string) {

    try {
        const stack = getStack();
        const responses = await stack
            .ContentType('product_pages')
            .Query()
            .where('product_slug_id', productSlugID)
            .includeReference(
                [
                    'content_sections.introduction.section.cta_button',
                    'content_sections.introduction.section.cta_button.meta.form',
                    'content_sections.value_prop.section',
                    'content_sections.cta.section.common_cta_button',
                    'content_sections.cta.section.common_cta_button.meta.form',
                    'content_sections.disclaimer.section',
                    'content_sections.case_studies.section',
                    'content_sections.process.section.modular_blocks.item.cta_button',
                    'content_sections.process.section.modular_blocks.item.cta_button.meta.form',
                    'content_sections.features_1.section',
                    'content_sections.features_2.section.modular_blocks.item.lists.list_item.cta_button',
                    'content_sections.features_2.section.modular_blocks.item.lists.list_item.cta_button.meta.form',
                    'content_sections.capabilities_1.section',
                    'content_sections.capabilities_2.section.modular_blocks.item.cta_button',
                    'content_sections.capabilities_2.section.modular_blocks.item.cta_button.meta.form',
                    'content_sections.awards.section'
                ]
            )
            .toJSON()
            .find();
        return getFirstEntry(responses);
    }
    catch (err) {
        if (err === 'Unprocessable Entity') {
            return null;
        }
        else {
            throw err;
        }
    }
}
