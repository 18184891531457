
import Vue from 'vue';
import {getProductFromContentStack} from '~/services/content-stack/products';
import { MetaInfo } from 'vue-meta';
import MdsLoader from '@mds/loader';
import {PageNotFoundError} from '~/configs/errors';

export default Vue.extend({
    data() {
        return {
            productData: {
                content_sections: [] as any[]
            }
        };
    },
    components:{
        MdsLoader
    },
    head(): MetaInfo {
        return this.$store.state.headElement?.meta;
    },
    watch: {
        "$route": function () {
            this.$fetch();
        }
    },
    async fetch() {
        try {
            if(!this.$route.params.product) {
                throw new PageNotFoundError;
            }

            const response = await getProductFromContentStack(this.$route.params.product);

            if(!response|| !response.content_sections) {
                throw new PageNotFoundError;
            }

            this.productData.content_sections = response.content_sections;
        }
        catch(e: any) {
            this.$fetchState.error = e;

            if(process.server) {
                this.$nuxt.context.res.statusCode = e.statusCode;
            }
            else {
                this.$nuxt.error(e);
            }
        }

        await this.$store.dispatch("headElement/fetchContentHeadElements", {
            canonical: `https://${this.$store.state.host}${this.$route.path}`,
            slug_id: `${this.$route.path}`,
        });
    }

});
